<template>
  <div class="app-container">
    <el-form :inline="true" class="filter">
      <el-form-item>
        <el-button size="small" type="success" @click="handleCreate"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
      size="mini"
      stripe
      border
      :data="tableData"
      highlight-current-row
      expand-on-click-node="false"
      style="width: 100%"
      :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
    >
      <el-table-column prop="Code" label="代码" width="120"> </el-table-column>
      <el-table-column prop="Name" label="名称" min-width="120">
      </el-table-column>
      <el-table-column prop="ImagePath" label="图片" width="100">
        <template slot-scope="scope">
          <viewer v-if="scope.row.ImagePath">
            <img
              v-if="scope.row.ImagePath"
              :src="baseUrl + scope.row.ImagePath"
              :key="scope.row.ImagePath"
              style="height: 40px"
            />
          </viewer>
        </template>
      </el-table-column>
      <el-table-column prop="备注" label="备注" min-width="80">
      </el-table-column>
      <el-table-column sortable prop="InsertTime" label="创建时间" width="150">
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="handleDelete(cope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizesList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
    <!--新增界面-->
    <div v-if="formVisible">
      <el-dialog
        v-dragDialog
        :title="formTitle"
        :visible.sync="formVisible"
        width="600px"
        :close-on-click-modal="false"
      >
        <el-form
          :model="addForm"
          ref="dataForm"
          label-width="120px"
          :rules="formRules"
        >
          <el-form-item label="代码:" prop="Code">
            <el-input
              v-model="addForm.Code"
              auto-complete="off"
              placeholder="请输入代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="名称:" prop="Name">
            <el-input
              v-model="addForm.Name"
              auto-complete="off"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传图片:" prop="ImagePath">
            <img
              v-if="addForm.ImagePath"
              :src="baseUrl + addForm.ImagePath"
              :key="addForm.ImagePath"
              style="height: 60px"
            />
            <el-button
              class="submit-btn"
              type="warning"
              size="mini"
              @click="selectFile"
              >点此上传</el-button
            >
          </el-form-item>
          <el-form-item label="备注：" prop="Desc">
            <el-input
              v-model="addForm.Desc"
              auto-complete="off"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="formVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <fileUploadNoUI ref="fileUploadNoUI" @uploadSuccess="uploadSuccess" />
  </div>
</template>
  <script>
import fileUploadNoUI from "@/components/fileUploadNoUI";
import { getPageList, save, del } from "@/api/prescription/musclePatch";
export default {
  components: {
    fileUploadNoUI,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      dialogStatus: "",
      formVisible: false,
      fromTitle: "肌贴方案",
      addForm: {},
      formRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "change" }],
        Code: [{ required: true, message: "请输入代码", trigger: "change" }],
        ImagePath: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    selectFile() {
      this.$refs.fileUploadNoUI.chooseFile();
    },
    uploadSuccess(val) {
      this.addForm.ImagePath = val.Path;
    },
    queryAll() {
      let para = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: "",
      };
      getPageList(para).then((response) => {
        if (response) {
          this.tableData = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleCreate() {
      this.formTitle = "添加肌贴方案";
      this.formVisible = true;
      this.addForm = {
        Name: "",
        Code: "",
        ImagePath: "",
        Desc: "",
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let para = Object.assign(this.addForm);
          this.$confirm("确认提交吗？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
          }).then(() => {
            save(para).then((response) => {
              if (response) {
                this.formVisible = false;
                this.queryAll();
              }
            });
          });
        }
      });
    },
    handleUpdate(row) {
      this.addForm = JSON.parse(JSON.stringify(row));
      this.formTitle = "修改肌贴方案";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    //删除
    handleDelete: function (row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        del(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.queryAll();
          }
        });
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getUserList());
    },

    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getUserList();
    },
  },
  mounted() {
    this.queryAll();
  },
};
</script>
  