<template>
  <div>
    <attchmentEditor
      :attachments="breath.Attachments"
      bussiness="Breath"
      @uploaded="uploadSuccess"
      :readOnly="disable"
      desc="请上传静态肺标准报告、呼吸报告"
    ></attchmentEditor>
    <el-form
      :model="breath"
      :rules="breathRules"
      ref="Breath"
      label-width="100px"
      :disabled="disable"
      label-position="left"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>肺功能与呼吸肌</span>
            <el-button type="warning" size="mini" @click="dispatchTest"
              >派发呼吸肌评估({{
                breath.TaskUuid ? breath.TaskUuid : "未下发"
              }})</el-button
            >
            <el-button
              v-if="breath.TaskUuid"
              type="success"
              size="mini"
              @click="handleRefresh"
              >刷新呼吸肌结果</el-button
            >
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-row">
                <div class="form-item">
                  <div class="form-item-label">实测值</div>
                  <el-form-item label="肺活量" prop="VitalCapacity">
                    <el-input-number
                      v-model="breath.VitalCapacity"
                      size="small"
                    ></el-input-number
                    >ml/min
                  </el-form-item>
                  <el-form-item label="用力肺活量" prop="ExertVitalCapacity">
                    <el-input-number
                      v-model="breath.ExertVitalCapacity"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>
                  <el-form-item label="一秒量" prop="OneSecond">
                    <el-input-number
                      v-model="breath.OneSecond"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>
                  <el-form-item label="一秒率" prop="OneSecondRate">
                    <el-input-number
                      v-model="breath.OneSecondRate"
                      size="small"
                    ></el-input-number
                    >%
                  </el-form-item>
                  <el-form-item label="最大通气量" prop="MaxGas">
                    <el-input-number
                      v-model="breath.MaxGas"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>
                  <el-form-item
                    label="最大吸气压"
                    label-width="200"
                    prop="MaxAbsorbPressure"
                  >
                    <el-input-number
                      v-model="breath.MaxAbsorbPressure"
                      size="small"
                    ></el-input-number
                    >cmH2O
                  </el-form-item>
                  <el-form-item
                    label="最大呼气压"
                    label-width="200"
                    prop="MaxExhalePressure"
                  >
                    <el-input-number
                      v-model="breath.MaxExhalePressure"
                      size="small"
                    ></el-input-number
                    >cmH2O
                  </el-form-item>
                </div>
                <div class="form-item">
                  <div class="form-item-label">预测值</div>
                  <el-form-item label="肺活量" prop="VitalCapacityForecast">
                    <el-input-number
                      v-model="breath.VitalCapacityForecast"
                      size="small"
                    ></el-input-number
                    >ml/min
                  </el-form-item>
                  <el-form-item
                    label="用力肺活量"
                    prop="ExertVitalCapacityForecast"
                  >
                    <el-input-number
                      v-model="breath.ExertVitalCapacityForecast"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>
                  <el-form-item label="一秒量" prop="OneSecondForecast">
                    <el-input-number
                      v-model="breath.OneSecondForecast"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>
                  <el-form-item label="一秒率" prop="OneSecondRateForecast">
                    <el-input-number
                      v-model="breath.OneSecondRateForecast"
                      size="small"
                    ></el-input-number
                    >%
                  </el-form-item>
                  <el-form-item label="最大通气量" prop="MaxGasForecast">
                    <el-input-number
                      v-model="breath.MaxGasForecast"
                      size="small"
                    ></el-input-number
                    >L
                  </el-form-item>

                  <el-form-item
                    label="最大吸气压"
                    label-width="200"
                    prop="MaxAbsorbForecast"
                  >
                    <el-input-number
                      v-model="breath.MaxAbsorbForecast"
                      size="small"
                    ></el-input-number
                    >cmH2O
                  </el-form-item>
                  <el-form-item
                    label="最大呼气压"
                    label-width="200"
                    prop="MaxExhaleForecast"
                  >
                    <el-input-number
                      v-model="breath.MaxExhaleForecast"
                      size="small"
                    ></el-input-number
                    >cmH2O
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>评估结果</span>
          </div>
          <el-form-item label-width="120" label="得分" prop="Score">
            <el-tag>{{ score }}</el-tag>
          </el-form-item>
          <el-form-item
            label-width="120"
            label="静态肺评估结果"
            prop="StaticSummary"
          >
            <el-input
              v-model="breath.StaticSummary"
              type="textarea"
              placeholder="请输入静态肺评估结果。"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="120"
            label="呼吸肌评估结果"
            prop="BreathSummary"
          >
            <el-input
              v-model="breath.BreathSummary"
              type="textarea"
              placeholder="请输入呼吸肌评估结果。"
            ></el-input>
          </el-form-item>
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import { loadFileData } from "@/api/check/check.js";
import { dispatch, refreshDevieResult } from "@/api/device/breathDevice.js";

import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.breath = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
  },
  components: { attchmentEditor, enumSelector },
  computed: {
    score() {
      let score1 = 0;
      if (this.breath.VitalCapacity >= this.breath.VitalCapacityForecast)
        score1 += 20;
      else score1 += 10;
      if (
        this.breath.ExertVitalCapacity >= this.breath.ExertVitalCapacityForecast
      )
        score1 += 10;
      else score1 += 5;
      if (this.breath.OneSecond >= this.breath.OneSecondForecast) score1 += 10;
      else score1 += 5;
      if (this.breath.OneSecondRate >= this.breath.OneSecondRateForecast)
        score1 += 20;
      else score1 += 10;
      if (this.breath.MaxGas >= this.breath.MaxGasForecast) score1 += 10;
      else score1 += 5;
      if (this.breath.MaxAbsorbPressure >= this.breath.MaxAbsorbForecast)
        score1 += 20;
      else score1 += 10;
      if (this.breath.MaxExhalePressure >= this.breath.MaxExhaleForecast)
        score1 += 10;
      else score1 += 5;
      this.breath.Score = score1;
      let breathSummary = "";
      if (this.breath.MaxAbsorbPressure >= this.breath.MaxAbsorbForecast) {
        breathSummary += "吸气肌力量非常好。";
      } else {
        breathSummary += "吸气肌力量需要训练。";
      }
      if (this.breath.MaxExhalePressure >= this.breath.MaxExhaleForecast) {
        breathSummary += "呼气肌力量非常好。";
      } else {
        breathSummary += "呼气肌力量需要训练。";
      }
      this.breath.BreathSummary = breathSummary;

      let staticSummary = "";
      let fev1 = false;
      if (this.breath.OneSecond && this.breath.OneSecondForecast) {
        if (this.breath.OneSecond / this.breath.OneSecondForecast < 0.8) {
          fev1 = true;
        }
      }
      let fvc = false;
      if (
        this.breath.ExertVitalCapacityForecast &&
        this.breath.ExertVitalCapacity
      ) {
        if (
          this.breath.ExertVitalCapacity /
            this.breath.ExertVitalCapacityForecast <
          0.8
        ) {
          fvc = true;
        }
      }

      let mvv = false;
      if (this.breath.MaxGas && this.breath.MaxGasForecast) {
        if (this.breath.MaxGas / this.breath.MaxGasForecast < 0.86) {
          mvv = true;
        }
      }
      if (fev1 || fvc) {
        staticSummary += "通气功能异常,";
      }
      if (mvv) {
        staticSummary += "通气功能储备异常,";
      }
      if (staticSummary) {
        staticSummary += "建议至专业医疗机构进一步诊治。";
      } else {
        staticSummary = "静态肺功能评估无异常。";
      }
      this.breath.StaticSummary = staticSummary;
      //静态肺功能评估无异常
      return this.breath.Score;
    },
  },
  data() {
    return {
      checkType: "Breath",
      //膝关节损伤
      breath: {},
      breathRules: {
        StaticSummary: { required: true, message: "请输入", trigger: "null" },
        BreathSummary: { required: true, message: "请输入", trigger: "null" },
        VitalCapacity: { required: true, message: "请输入", trigger: "null" },
        ExertVitalCapacity: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecond: { required: true, message: "请输入", trigger: "null" },
        OneSecondRate: { required: true, message: "请输入", trigger: "null" },
        MaxGas: { required: true, message: "请输入", trigger: "null" },
        VitalCapacityForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ExertVitalCapacityForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecondForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecondRateForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxGasForecast: { required: true, message: "请输入", trigger: "null" },

        MaxAbsorbPressure: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxAbsorbForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxExhalePressure: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxExhaleForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
      },
    };
  },
  methods: {
    uploadSuccess(val) {
      if (
        val.file.Type == "BreatheReport" ||
        val.file.Type == "BreathStaticStandard"
      ) {
        let param = {
          checkKey: this.checkKey,
          checkType: val.bussiness,
          attachType: val.file.Type,
          dataFile: val.file.Path,
          checkItem: this.breath,
        };
        loadFileData(param).then((res) => {
          if (res) {
            this.breath = res;
          }
        });
      }
    },
    handleRefresh() {
      if (!this.breath.Key) {
        this.msg.errorMsg("没有下发测试，不可刷新！");
        return;
      }
      refreshDevieResult({ key: this.breath.Key }).then((res) => {
        if (res) {
          this.breath.MaxAbsorbForecast = res.MaxAbsorbForecast;
          this.breath.MaxAbsorbPressure = res.MaxAbsorbPressure;
          this.breath.MaxExhaleForecast = res.MaxExhaleForecast;
          this.breath.MaxExhalePressure = res.MaxExhalePressure;
          this.msg.successMsg("刷新完成！");
        }
      });
    },
    //下发测试任务
    dispatchTest() {
      let msg ="确定下发评估任务吗？";
      if(this.breath.TaskUuid){
        msg="已经下发过评估任务，确定再次下发吗？";
      }
      this.$confirm(msg, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          checkKey: this.checkKey,
          breathKey: this.breath.Key ? this.breath.Key : "",
        };
        dispatch(param).then((res) => {
          if (res) {
            this.breath.Key = res.key;
            this.breath.TaskUuid = res.taskId;
            this.msg.successMsg("下发成功！");
          }
        });
      });
    },
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.breath.Attachments) {
        this.$set(this.breath, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.breath);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      padding-right: 50px;
      .form-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .form-item-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
        .form-item-label {
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          width: 100%;
          font-weight: bolder;
        }
      }
    }
  }

  .input200 {
    width: 200px;
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>