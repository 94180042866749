import request from '@/utils/request'


export function ayncDevice(data) {
  return request({
    url: 'breathDevice/ayncDevice',
    method: 'post',
    data,
  })
}

export function dispatch(data) {
  return request({
    url: 'breathDevice/dispatch',
    method: 'post',
    data,
  })
}

export function refreshDevieResult(data) {
  return request({
    url: 'breathDevice/refreshDevieResult',
    method: 'post',
    data,
  })
}

export function getPageList(data) {
  return request({
    url: 'breathDevice/getPageList',
    method: 'post',
    data,
  })
}

export function setCanUse(data) {
    return request({
      url: 'breathDevice/setCanUse',
      method: 'post',
      data,
    })
  }

  export function setTenant(data) {
    return request({
      url: 'breathDevice/setTenant',
      method: 'post',
      data,
    })
  }

  export function save(data) {
    return request({
      url: 'breathDevice/save',
      method: 'post',
      data,
    })
  }
  


