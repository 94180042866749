var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("attchmentEditor", {
        attrs: {
          attachments: _vm.breath.Attachments,
          bussiness: "Breath",
          readOnly: _vm.disable,
          desc: "请上传静态肺标准报告、呼吸报告"
        },
        on: { uploaded: _vm.uploadSuccess }
      }),
      _c(
        "el-form",
        {
          ref: "Breath",
          attrs: {
            model: _vm.breath,
            rules: _vm.breathRules,
            "label-width": "100px",
            disabled: _vm.disable,
            "label-position": "left"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c("span", [_vm._v("肺功能与呼吸肌")]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", size: "mini" },
                        on: { click: _vm.dispatchTest }
                      },
                      [
                        _vm._v(
                          "派发呼吸肌评估(" +
                            _vm._s(
                              _vm.breath.TaskUuid
                                ? _vm.breath.TaskUuid
                                : "未下发"
                            ) +
                            ")"
                        )
                      ]
                    ),
                    _vm.breath.TaskUuid
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: { click: _vm.handleRefresh }
                          },
                          [_vm._v("刷新呼吸肌结果")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "box-card-body" }, [
                  _c("div", { staticClass: "form" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-item" },
                        [
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("实测值")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "肺活量", prop: "VitalCapacity" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.VitalCapacity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.breath, "VitalCapacity", $$v)
                                  },
                                  expression: "breath.VitalCapacity"
                                }
                              }),
                              _vm._v("ml/min ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用力肺活量",
                                prop: "ExertVitalCapacity"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.ExertVitalCapacity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "ExertVitalCapacity",
                                      $$v
                                    )
                                  },
                                  expression: "breath.ExertVitalCapacity"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "一秒量", prop: "OneSecond" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.OneSecond,
                                  callback: function($$v) {
                                    _vm.$set(_vm.breath, "OneSecond", $$v)
                                  },
                                  expression: "breath.OneSecond"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "一秒率", prop: "OneSecondRate" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.OneSecondRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.breath, "OneSecondRate", $$v)
                                  },
                                  expression: "breath.OneSecondRate"
                                }
                              }),
                              _vm._v("% ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "最大通气量", prop: "MaxGas" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxGas,
                                  callback: function($$v) {
                                    _vm.$set(_vm.breath, "MaxGas", $$v)
                                  },
                                  expression: "breath.MaxGas"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大吸气压",
                                "label-width": "200",
                                prop: "MaxAbsorbPressure"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxAbsorbPressure,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "MaxAbsorbPressure",
                                      $$v
                                    )
                                  },
                                  expression: "breath.MaxAbsorbPressure"
                                }
                              }),
                              _vm._v("cmH2O ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大呼气压",
                                "label-width": "200",
                                prop: "MaxExhalePressure"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxExhalePressure,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "MaxExhalePressure",
                                      $$v
                                    )
                                  },
                                  expression: "breath.MaxExhalePressure"
                                }
                              }),
                              _vm._v("cmH2O ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-item" },
                        [
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("预测值")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "肺活量",
                                prop: "VitalCapacityForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.VitalCapacityForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "VitalCapacityForecast",
                                      $$v
                                    )
                                  },
                                  expression: "breath.VitalCapacityForecast"
                                }
                              }),
                              _vm._v("ml/min ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用力肺活量",
                                prop: "ExertVitalCapacityForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.ExertVitalCapacityForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "ExertVitalCapacityForecast",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "breath.ExertVitalCapacityForecast"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "一秒量",
                                prop: "OneSecondForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.OneSecondForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "OneSecondForecast",
                                      $$v
                                    )
                                  },
                                  expression: "breath.OneSecondForecast"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "一秒率",
                                prop: "OneSecondRateForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.OneSecondRateForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "OneSecondRateForecast",
                                      $$v
                                    )
                                  },
                                  expression: "breath.OneSecondRateForecast"
                                }
                              }),
                              _vm._v("% ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大通气量",
                                prop: "MaxGasForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxGasForecast,
                                  callback: function($$v) {
                                    _vm.$set(_vm.breath, "MaxGasForecast", $$v)
                                  },
                                  expression: "breath.MaxGasForecast"
                                }
                              }),
                              _vm._v("L ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大吸气压",
                                "label-width": "200",
                                prop: "MaxAbsorbForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxAbsorbForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "MaxAbsorbForecast",
                                      $$v
                                    )
                                  },
                                  expression: "breath.MaxAbsorbForecast"
                                }
                              }),
                              _vm._v("cmH2O ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大呼气压",
                                "label-width": "200",
                                prop: "MaxExhaleForecast"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.breath.MaxExhaleForecast,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.breath,
                                      "MaxExhaleForecast",
                                      $$v
                                    )
                                  },
                                  expression: "breath.MaxExhaleForecast"
                                }
                              }),
                              _vm._v("cmH2O ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("评估结果")])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120",
                        label: "得分",
                        prop: "Score"
                      }
                    },
                    [_c("el-tag", [_vm._v(_vm._s(_vm.score))])],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120",
                        label: "静态肺评估结果",
                        prop: "StaticSummary"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入静态肺评估结果。"
                        },
                        model: {
                          value: _vm.breath.StaticSummary,
                          callback: function($$v) {
                            _vm.$set(_vm.breath, "StaticSummary", $$v)
                          },
                          expression: "breath.StaticSummary"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120",
                        label: "呼吸肌评估结果",
                        prop: "BreathSummary"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入呼吸肌评估结果。"
                        },
                        model: {
                          value: _vm.breath.BreathSummary,
                          callback: function($$v) {
                            _vm.$set(_vm.breath, "BreathSummary", $$v)
                          },
                          expression: "breath.BreathSummary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }