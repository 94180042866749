<template>
  <div class="loginfo-container">
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-button size="small" type="success" v-on:click="handleAsyncDevice"
            >同步设备</el-button
          >
        </el-form-item>
        <el-form-item label="使用门店">
          <shopSelector v-model="filters.tenant"></shopSelector>
        </el-form-item>
        <el-form-item  label="关键字">
          <el-input
            size="small"
            v-model="filters.search"
            placeholder="编号,名称关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" v-on:click="queryPageList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      stripe
      border
      size="mini"
      :data="tableData"
      highlight-current-row
      expand-on-click-node="false"
      :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
      style="width: 100%"
    >
      <el-table-column prop="DeviceCode" label="设备编号" min-width="120" sortable>
        <template slot-scope="scope">
          <el-input
            size="small"
            v-model="scope.row.DeviceCode"
            @change="handleSave(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="Tenant.Name"
        label="使用门店"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          <shopSelector
            size="small"
            v-model="scope.row.Tenant"
            @change="handleSave(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="OrgName"
        label="云平台机构（门店名称）"
        min-width="100"
        sortable
      ></el-table-column>
      <el-table-column
        prop="DeptName"
        label="云平台部门（设备名称）"
        min-width="100"
        sortable
      ></el-table-column>
      <el-table-column prop="CanUse" label="是否启用" min-width="120" sortable>
        <template slot-scope="scope">
          <el-switch
            size="small"
            v-if="scope.row.CanUse"
            v-model="scope.row.CanUse"
            @change="handleSave(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="DispatchDate"
        label="最后分配时间"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.DispatchDate) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizesList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
  <script>
import shopSelector from "@/components/shopSelector";
import {
  getPageList,
  ayncDevice,
  setTenant,
  setCanUse,
  save,
} from "@/api/device/breathDevice";

export default {
  components: {
    shopSelector,
  },
  data() {
    return {
      filters: {
        search: "",
      },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
    };
  },
  methods: {
    handleAsyncDevice() {
      this.$confirm("确认同步云平台设备吗？", "提示", {}).then(() => {
        ayncDevice({}).then((res) => {
          if (res) {
            this.msg.successMsg("同步完成！");
          }
        });
      });
    },
    queryPageList: function () {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        tenant: this.filters.tenant ? this.filters.tenant.Key : "",
        search: this.filters.search,
      };
      getPageList(data).then((response) => {
        this.listLoading = false;
        if (response) {
          this.tableData = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.queryPageList());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.queryPageList();
    },
    handleSave(row) {
      save(row).then((res) => {
        if (res) {
          this.msg.successMsg("操作完成！");
        }
      });
    },
  },
  mounted() {
    this.queryPageList();
  },
};
</script>
  <style lang='scss' scoped>
.loginfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
  